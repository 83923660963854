/* Table Variables */
$table-background-color: #5280e1;
$table-text-color: #1f2b5b;
$table-border-radius: 35px !important;

/* Card Variables */

$card-header-background-color: #5280e1;

/* Modal Variables */

$modal-background-color: #5686ea;
$modal-header-inner-padding: 1rem;
$modal-inner-padding: 1rem 1rem 2rem 1rem;
$modal-body-text-color:#1f2b5b;

/* Button variables */
$button-color-login: #1d46d6;
$button-color-add: #9dd368;
$button-color-cancel:#de3838;