
@use "../node_modules/ag-grid-community/styles" as ag;
@import 'ngx-toastr/toastr';

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import '../node_modules/bootstrap/scss/mixins';

@import '../src/assets/styles/tables';
@import '../src/assets/styles/cards';
@import '../src/assets/styles/modals';

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
@import '../src/assets/styles/mixins';
@import 'ngx-toastr/toastr-bs5-alert';


.btn-rounded {
    border-radius: 20px;
    font-size: 18px;
    font-weight: bold;
    line-height: 20px;
    font-style: normal;
    padding: 10px 25px;
    filter: drop-shadow(0px 6px 3px rgba(0, 0, 0, 0.46));
 }

.card-blue-header {
    background-color: #5686EA;
    color: white;
    padding: 1em;
}
.card-blue-container { 
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-radius: 2em;
    overflow: hidden;
}

.form-control {
    &::placeholder {
        color: #ccc;
    }
}